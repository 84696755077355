import Helmet from "react-helmet"
import React from "react"
import ThankYouPage from "src/components/typ"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"


export default function ThankYou() {
  if(isChrome()){
    Data.logo = Data.headerLogo;
  }
  if(isFirefox()){
    Data.logo = Data.headerLogo;
  }
  return(
    <>
    <Helmet>
      <style type="text/css">{`
           .typ-module--typContainer h1.typ-module--typHL {
            font-size: 34pt!important;
          }

        `}
        </style>
        <title>Thank You for Downloading PDF.live Chrome Extension</title>
      </Helmet>
    <section>
      <ThankYouPage data={Data}></ThankYouPage>
    </section>
    </>
  )
}
