import React from "react"
import Styles from "./css/typ.module.scss"
import {isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"
import Footerlinks from "@tightrope/footerlinks/footer"


class Typ extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      pageData: {},
      data: {},
      siteData: {}
    }
  }
  componentDidMount(){
    let data = this.props.data;
    let siteData = this.props.siteData;
    this.setState({data: data, siteData: siteData});
    if(isChrome()){
      const browserData = Object.assign(data, data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(data, data.firefox);
      this.setState({data: browserData});
    }
  }

  render() {
    return (
    <div className={Styles.typContainer}>
      <div className={Styles.typContainerwrapper}>
        <img src={this.state.data.logo} className={Styles.typLogo} />
        <h1 className={Styles.typHL}>Thank You for adding {this.state.data.productName}!</h1>
        <img src={this.state.data.tyProductIMG} className={Styles.typimg} />
      </div>
    <Footerlinks></Footerlinks>
    </div>
    )
  }
}

export default Typ
